import { Dialog } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}
const ModalComp: React.FC<Props> = ({ showModal, setShowModal }) => {
  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      maxWidth="lg"
      sx={{ borderRadius: "10px" }}
    >
      <div className="bg-white lg:w-[661px] px-[10px] lg:px-[38px] py-[48px] relative">
        <span
          onClick={() => setShowModal(false)}
          className="absolute right-[14px] top-[14px] text-[1.5rem] cursor-pointer"
        >
          <RxCross2 />
        </span>

        <h1 className="text-[20px] lg:text-[28px] font-edosz pb-[34px]">
          Here are some tips for a sAFe transaction
        </h1>
        <ul className="list-disc flex flex-col gap-[22px] font-[500]">
          <li className="text-[12px] lg:text-[14px] tracking-wider">
            Make sure you are visiting our official websites only
            <a href="https://www.partisians.com" className="underline text-[#19B47D] mx-[.3rem]">
              www.partisians.com
            </a>
            and{" "}
            <a href="https://www.partisians.com" className="underline text-[#19B47D] mx-[.3rem]">
              www.partisians.live
            </a>{" "}
            and our official discord{" "}
            <a href="https://discord.gg/partisiansnft" className="underline text-[#19B47D] mx-[.3rem]">
              https://discord.gg/partisiansnft
            </a>
          </li>
          <li className="text-[12px] lg:text-[14px] tracking-wider">
            Check that the official wallet address is connected to our ens
            domain partisians.eth
          </li>
          <li className="text-[12px] lg:text-[14px] tracking-wider">
            Log in to multiple social channels to verify that everything looks
            normal
          </li>
          <li className="text-[12px] lg:text-[14px] tracking-wider">
            Send a quick email to{" "}
            <a href="https://www.support@partisians.com" className="underline text-[#19B47D] mx-[.3rem]">
              support@partisians.com
            </a>{" "}
            message an official team member on Discord, or DM our official
            twitter account
            <a href="https://twitter.com/PartisiansNFT" className="underline text-[#19B47D] mx-[.3rem]">
              https://twitter.com/PartisiansNFT
            </a>{" "}
            for an extra step. Our team is very busy but we’ll try to respond as
            fast as we can!
          </li>
          <li className="text-[12px] lg:text-[14px] tracking-wider">
            Keep in mind that in Web 3.0 security breaches can happen and there
            is always a risk no matter how many precautions we take together.
            Onwards
          </li>
        </ul>
      </div>
    </Dialog>
  );
};

export default ModalComp;
