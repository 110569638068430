import { useEffect, useState } from "react";
import LeftSide from "../Components/Order/LeftSide";
import RightSide from "../Components/Order/RightSide";
import ModalComp from "../Components/Shared/ModalComp";
import axios from "axios";
import { collection, doc, getDocs, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
signInWithEmailAndPassword(auth, process.env.REACT_APP_EMAIL as string, process.env.REACT_APP_PASSWORD as string)
const Order = () => {
  // MODAL STATE
  const [payment, setPayment] = useState<number>(0);
  const [nftValue, setNftValue] = useState<number>(1);
  const [price, setPrice] = useState<number>(.2);
  const [coinType, setCoinType] = useState<string>("ETH");
  const [total, setTotal] = useState<number>(.2);
  const [available, setAvailable] = useState<number>(200)

  async function snapshot() {
    auth.onAuthStateChanged(async function(user) {
      if (user) {
        //console.log("signed in sucessfully")
        const txRef = collection(db,"Counter");
        const snapshotTx = await getDocs(txRef);
        
      snapshotTx.forEach((doc) => {
      
        if (available === 0){
          const totaltx = 200
          setAvailable(totaltx)
          return totaltx
        }else{
          const totaltx = 200 - doc.data().Total
          setAvailable(totaltx)
          return totaltx
        }
      });
      } else {
        //console.log("No user is signed in.")
      }
  });
  }
  snapshot();

  useEffect(() => {
    
  }, []);
  
  
  return (
    <div className="flex flex-col lg:flex-row justify-between lg:px-0 py-[63px] gap-[48px] lg:gap-0">
      {/* $$$ LEFT SIDE */}
      <div>
        <LeftSide
          price={price}
          nftValue={nftValue}
          setNftValue={setNftValue}
          coinType={coinType}
          setPrice={setPrice}
          setTotal={setTotal}
          available={available}
        />
      </div>
      {/* $$$ RIGHT SIDE */}
      <div className="px-[20px] lg:px-0">
        <RightSide
          coinType={coinType}
          setCoinType={setCoinType}
          payment={payment}
          nftValue={nftValue}
          setPayment={setPayment}
          price={price}
          total={total}
          setPrice={setPrice}
          setTotal={setTotal}
          available={available}
          setAvailable={setAvailable}
        />
      </div>

      {/* $$$ MODAL */}
    </div>
  );
};

export default Order;

