import React, { useState } from "react";
import orderImg from "../../Assets/order-image.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import etheriumIcon from "../../Assets/etherium-icon.png";
import usdcIcon from '../../Assets/usdc.jpeg';
import { useNavigate } from "react-router-dom";

import NFT_MINT_FORM from "./NFT_Mint";
import USDC_Payment_Form from "./USDC_Payment";
import { FaAngleDown } from "react-icons/fa";
import ErrorModal from "../Shared/ErrorModal";

interface Props{
  payment: number,
  setPayment: any,
  coinType: string,
  setCoinType: any,
  price: number,
  nftValue:number,
  total:number,
  setPrice: any,
  setTotal: any,
  available: number,
  setAvailable: any,
}

const payemntMethods = [
  {
    name:"Ethereum (ETH)",
    image: etheriumIcon
  },
 
];
const RightSide: React.FC<Props> = ({payment, setPayment, setCoinType, price, nftValue, total, coinType, setPrice, setTotal, setAvailable, available}) => {

  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [discord, setDiscord] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  // DROPDOWN STATE
  const handleChange = (event: any) => {
    const method = event.target.value
    setPayment(method);
    if(method === 0){
      setCoinType('ETH')

    }else{
      setCoinType('USDC')
      setPrice(250)
      setTotal(250*nftValue)
    }
   
  };
  return (
    <div className="w-[100%] lg:w-[482px] bg-[#FFFFFF2B] text-white px-[24px] pt-[12px] pb-[28px] rounded-[8px]">
      {/* $$$ TOP PART */}
      <div>
        {/* $$ */}
        <div>
          <h3 className="text-[24px] text-center">Your Order</h3>
          <div className="text-[14px] flex justify-between">
            <span>Item</span>
            <span className="underline">Clear all</span>
          </div>
        </div>
        {/* $$ */}
        <div className="flex items-center gap-[12px]">
          <div>
            <img
              className="w-[56px] lg:w-[80px] h-[59px] lg:h-[83px] object-contain"
              src={orderImg}
              alt="card-img"
            />
          </div>
          <div>
            <p className="text-[12px] lg:text-[1rem]">
              1 Partisians NFT at Public Mint and Partisians Hall of Fame Pass
            </p>
          </div>
          <div className="whitespace-nowrap">
            <h3 className="text-[14px] lg:text-[1rem]">{coinType === 'ETH' ? price.toFixed(4) : `$${price}`} {coinType === 'ETH' ? coinType : 'erc20'}</h3>
          </div>
        </div>
        {/* $$ */}
        <div>
          <div className="flex justify-between">
            <h3 className="text-[18px]">Total Price</h3>
            <h3 className="text-[14px] lg:text-[18px]">{coinType === 'ETH' ? total.toFixed(4) : `$${total}`} {coinType === 'ETH' ? coinType : 'erc20'}</h3>
          </div>
          {/* <h3 className="text-[12px] lg:text-[14px] text-[#D7D7D7] text-end"> erc20</h3> */}
        </div>
      </div>
      {/* $$$ DIVIDER */}
      <div className="h-[1px] w-[100%] bg-[#FFFFFF50] my-[1rem]"></div>
      {/* $$$ BOTTOM PART */}
      <div className="flex flex-col gap-[1.5rem] tracking-wider">
        {/* $$ PAYMENT METHOD */}
        <div className="flex flex-col gap-[12px]">
          <label className="text-[14px] font-[600]" htmlFor="paymentMethod">
            Payment Method
          </label>
          <FormControl className="w-[100%] h-[50px]">
            <Select
              value={payment}
              onChange={handleChange}
              // displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={FaAngleDown}
              className="w-[100%]"
              // labelStyle={{ color: "#ff0000" }}
              sx={{
                borderRadius: "10px",
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 1)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 1)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 1)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                },
              }}
            >
              {payemntMethods?.map((el, i) => (
                <MenuItem key={i} value={i}>
                  <div className="flex items-center gap-[.5rem]">
                    <img src={el.image} alt="icon" />
                    <p>{el.name}</p>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* $$ Your Email */}
        <div className="flex flex-col gap-[12px]">
          <label className="text-[14px] font-[600]" htmlFor="email">
            Your Email
          </label>
          <input
            className="bg-transparent h-[50px] w-[100%] border border-white rounded-[10px] focus:border-[2px] focus:outline-none px-[1rem]"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={(e:any)=> setEmail(e.target.value)}
          />
        </div>
        {/* $$ Share your Discord handle to enter Partisians Hall of Fame    */}
        <div className="flex flex-col gap-[12px]">
          <label className="text-[14px] font-[600]" htmlFor="userName">
            Share your Discord handle to enter Partisians Hall of Fame
          </label>
          <input
            className="bg-transparent h-[50px] w-[100%] border border-white rounded-[10px] focus:border-[2px] focus:outline-none px-[1rem]"
            type="text"
            name="userName"
            id="userName"
            placeholder="Username"
            onChange={(e:any)=> setDiscord(e.target.value)}
          />
        </div>
        {/* $$ SUBMIT BUTTON */}
        {payment === 0 ? <NFT_MINT_FORM available={available} setAvailable={setAvailable} email={email} discord={discord} nftValue={nftValue} coinType={coinType} errorMessage={errorMessage} setShowModal={setShowModal} setErrorMessage={setErrorMessage} setTotal={total}/> : <USDC_Payment_Form email={email} discord={discord}  nftValue={nftValue}  setShowModal={setShowModal} coinType={coinType} setErrorMessage={setErrorMessage} errorMessage={errorMessage} setTotal={total} available={available} setAvailable={setAvailable}/>}
      </div>
      <ErrorModal showModal={showModal} setShowModal={setShowModal} errorMessage={errorMessage}></ErrorModal>
    </div>
  );
};

export default RightSide;
