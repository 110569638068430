export const fakeData = [
  { name: "bored-ape-yacht-club", marketCap: 7000000, currentPrice: 600 },
  { name: "azuki", marketCap: 200, currentPrice: 454 },
  { name: "doodles-official", marketCap: 342, currentPrice:  565},
  { name: "degods", marketCap: 400, currentPrice: 323 },
  { name: "partisians", marketCap: 150, currentPrice:  234},
  { name: "the-potatoz", marketCap: 100, currentPrice: 324 },
  { name: "moonbirds", marketCap: 500, currentPrice: 231 },
  { name: "pudgy-penguins", marketCap: 450, currentPrice: 123 },
  { name: "cool-cats", marketCap: 380, currentPrice: 678 },
  { name: "you-the-real-mvp", marketCap: 250, currentPrice: 432 },
];


