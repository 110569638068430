import { useState } from "react";
const Priv = () => {
  const [showPriv, setPriv] = useState(false);
  const onClick = () => setPriv(!showPriv);
  return (
    <div onClick={onClick}>
      <h1 className="cursor-pointer text-white"> Privacy Policy</h1>

      {showPriv ? <DATA /> : null}
    </div>
  );
};
export default Priv;

const DATA = () => {
  return (
    <div>
      <p className="cursor-pointer text-white">
        This Partisians NFT Privacy Policy (“Policy”) sets out what information
        Partisians NFT (“we”, “our” or “us”) collects, how we collect it, and
        what we do with it. This Policy applies to any visitor of any of our
        websites. If you have questions concerning this Policy, please contact
        us as indicated in the “How to Contact Us” section below. We may update
        this Policy from time to time, so please check this website periodically
        for changes. By using our site, you consent to our Policy. What
        information do we collect? We may collect two basic types of
        information, personal information and anonymous information, and we may
        use personal and anonymous information to create a third type of
        information, aggregate information. Personal and anonymous information
        may be collected directly from you through the use of our services and
        through this site. We collect information from you when you create an
        account, respond to a communication such as e-mail, participate in our
        chat rooms or message boards, or participate in another site feature.
        When creating an account, you may enter your name, e-mail address, and
        other information. Like many web sites, we may use "cookies" and other
        tracking mechanisms to enhance your experience and gather information
        about visitors and visits to our websites. We may collect at least the
        following: your domain name and host for Internet access; the Internet
        address of the site from which you came; the date and time of your
        access; your computer’s IP address and information about its operating
        system, browser and host; and the pages you visit. Please refer to the
        "Do we use 'cookies'?" section below for information about cookies and
        how we use them. How do we use your information? We may use information
        collected on the website, which may include your personal information,
        for various purposes. In general, we use the personal information we
        collect to pursue our mission and operations, including the management
        of our site and to engage in related activities. You should assume that
        we will use all information for all lawful purposes. We reserve the
        right, but do not undertake a duty, to notify you of court orders,
        subpoenas or other legal process if allowed. If you do not want us to
        respond to legal process compelling us to disclose your information, you
        need to seek a valid order permitting us to refuse to disclose it and
        serve the order on us at least three business days before the response
        deadline. We reserve the right to use, disclose, retain, share, sell and
        otherwise deal with all information collected via the site internally or
        to third parties, for any lawful purpose or to prevent harm to us or
        others. For example, and without limitation, in our discretion we may
        use the information to: Understand how visitors navigate through our
        websites, to enhance your experience while using our websites, and to
        make the materials we post as valuable to visitors as possible; Process
        your transactions; Send you periodic emails or electronic information
        (If you would no longer like to receive communications from us, please
        refer to the "How can you remove or modify information you have provided
        to us?" section below.); Disclose information to service providers and
        others in order to deal with requests and questions from you; and to
        assist or respond to our agents (including auditors); Disclose
        information to government regulators, law enforcement authorities or
        alleged victims of identity theft, as well as voluntary disclosures; and
        Disclose any matter relating to the site not listed in this Policy, but
        which is not prohibited by law from being disclosed. We do not purport
        to have listed all possible disclosures that we might make. This Policy
        is intended to help you understand our general practices. This Policy is
        not a promise that your information will never be disclosed except as
        described above. For example, third parties may unlawfully intercept or
        access information transmitted to or contained on the site, technologies
        may malfunction or not work as anticipated, or someone might access,
        abuse or misuse information, despite a lack of permission. Although we
        use what we believe to be commercially reasonable practices to protect
        your privacy, that does not mean, and you should not expect it to mean,
        that your information or communications will always be private or
        protected. We generally will retain information for as long as required,
        allowed or for as long as we believe it useful. If you cease using the
        site, or your permission to use the site is terminated, we may continue
        to use and disclose your personal information in accordance with this
        Policy as amended from time to time. We do not undertake retention
        obligations through this Policy. We may dispose of information at our
        discretion without notice, subject to applicable law. DISCLAIMER
        REGARDING SECURITY WE DO NOT GUARANTEE THE SECURITY OF PERSONAL
        INFORMATION OR OTHER INFORMATION IN ANY FORM. We do implement a variety
        of security measures to maintain the safety of your personal
        information. Your personal information is contained behind secured
        networks and is only accessible by a limited number of persons. All
        sensitive information you supply is transmitted via Secure Socket Layer
        (SSL) technology and then encrypted into our databases to be only
        accessed as stated above. Do we use "cookies"? Yes. Cookies are small
        files that a site or its service provider transfers to your computer's
        hard drive through your web browser (if you allow them) that enables the
        site's or service provider's systems to recognize your browser and
        capture and remember certain information. For instance, we use cookies
        to help us compile aggregate data about site traffic and site
        interaction so that we can offer better site experiences and tools in
        the future. Subject to the capabilities of the web browser you use, you
        may restrict the use of certain cookies. We also use Google Analytics,
        which is a web analytics service provided by Google LLC ("Google").
        Google Analytics uses cookies to help the website analyze how
        individuals use the website. The non-personal information generated by
        the cookie about your use of the site will be transmitted to and stored
        by Google on servers in the United States. Google will use this
        information for the purpose of evaluating your use of the site,
        compiling reports on site activity for our purpose, evaluation and
        providing other services relating to the site activity and Internet
        usage. Google will not associate your IP address with any other data
        held by Google. You may disable cookies within your browser. However,
        please note that if you do this you may not be able to use the full
        functionality of the site. By using this site, you consent to the
        processing of data about you by Google in the manner and for the
        purposes set out above. Some browsers allow you to choose to have your
        computer warn you each time a cookie is being sent, and some browsers
        enable you to choose to turn off all cookies. You do this through the
        settings on your browser (like Chrome, Safari or Internet Explorer).
        Each browser is a little different, so look at your browser’s Help menu
        or other features to learn the correct way to modify the manner in which
        your browser handles cookies. If you turn cookies off, you may not have
        access to certain features on the site. Do we disclose the information
        we collect to outside parties? We may release your information when we
        believe release is appropriate to comply with the law; enforce our site
        policies; protect ours or others' rights, property, or safety; or as
        otherwise described in the “How do we use your information?” section
        above. How can you remove or modify information you have provided to us?
        To change or delete your online account information from our database,
        please contact us directly through one of the means identified in the
        “How to Contact Us” section below. Are you visiting our site from
        outside the United States? If you are visiting our website from outside
        of the United States or otherwise contacting us from outside of the
        United States, please be aware that your personal information may be
        transferred to, stored or processed in the United States, where our
        servers are located and our central database is operated. The data
        protection and other laws of the United States and other countries might
        not be as comprehensive as those in your country. By using our site, you
        understand that your information may be transferred to our facilities
        and those third parties with whom we share it as described in this
        Policy. California Residents Only - Disclosures to Direct Marketing
        Entities This notice applies only to California residents and is made
        pursuant to California Civil Code § 1798.83. As noted above, we may
        disclose personal information about you, such as your email address, to
        third parties. If we disclose personal information about you to a third
        party and the third party will use that information for direct marketing
        purposes, you have the right to contact us at support@partisians.com to
        request that we notify you as to: (1) what categories of personal
        information we shared with the direct marketer; and (2) the name and
        address of the direct marketing businesses with whom we shared such
        information, and if the nature of the direct marketing businesses cannot
        be reasonably determined from its name, examples of the products or
        services marketed. Third Party Links We may include third party links on
        our site. Many of these linked sites have separate and independent
        privacy policies. Except for sites controlled by us, we have no
        responsibility or liability for the content and activities of linked
        sites. Nonetheless, we seek to protect the integrity of our site and
        welcome any feedback about these linked sites (including if a specific
        link does not work). Consent to Electronic Notice If There is a Security
        Breach If we are required to provide notice of unauthorized access or
        other invasion of certain security systems, you agree that we may do so
        when required (or voluntarily) by posting notice on our website or
        sending notice to any email address we have for you, in our good faith
        discretion. You agree that notice to you will count as notice to others
        for whom you are acting, and agree to pass the notice on to them.
        Children We do not want to collect information from children. Do not
        provide any personal information unless you are at least 18 years of
        age, and please caution your children not to provide any. If a child
        under 18 has provided personal information, a parent or guardian may so
        inform us by contacting us through any means offered in the “How to
        Contact Us” section below, and we will use reasonable efforts to delete
        it from our database, subject to applicable law and this Policy.
        Identity Theft If you believe you are a victim of identity theft
        entitled by law to request information from us, write us at the address
        below and we’ll explain what information we require in order to respond.
        After receiving that information, we’ll supply (without charge)
        information we then have that we are legally required to provide
        (subject to applicable law and reserving all rights and defenses).
        Changes to Our Policy If we decide to change our Policy, we will post
        those changes on this page. Policy changes will apply only to
        information collected after the date of the change. How to Contact Us We
        welcome your questions, comments, and concerns about privacy. Please
        email us at support@partisians.com. Online Policy Only This Policy
        applies only to information collected online, including through our
        website, and not to information collected offline. Terms and Conditions
        Please also visit our Partisians NFT Terms of Use establishing the use,
        disclaimers, and limitations of liability governing the use of certain
        websites.
      </p>
    </div>
  );
};
