import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FaAngleDown } from "react-icons/fa";
import { BsArrowUpRight } from "react-icons/bs";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  nftValue: number;
  setNftValue: any;
  price: number;
  coinType: string;
  setPrice: any;
  setTotal: any;
  available: number
}


const nftValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const LeftSide: React.FC<Props> = ({
  nftValue,
  setNftValue,
  price,
  coinType,
  setPrice,
  setTotal,
  available
}) => {
  // DROPDOWN STATE
  
  const handleChange = (event: any) => {
    setNftValue(event.target.value);
    setTotal(price * event.target.value);
  };
  const handlePriceChange = (e: any) => {
    setPrice(e.target.value);
    setTotal(e.target.value * nftValue);
  };

  return (
    <div>
      {/* $$$$$$ FOR LARGE DEVICE */}
      <div className="hidden lg:block">
        {/* $$$ TOP PART */}
        <div>
          <h1 className="text-[32px] text-white">
            Partisians Hall of Fame Pass 🏆
          </h1>
          {/* $$ INPUT FIELDS */}
          <div className="flex items-center gap-[12px] text-white mt-[26px] tracking-wider whitespace-nowrap">
            {/* $ Current Price */}
            <div className="flex flex-col gap-[12px] relative">
              <label htmlFor="currentPrice">Current Price</label>
              <input
                value={`${coinType === "ETH" ? price.toFixed(4) : price}`}
                className="w-[180px] h-[40px] bg-[#FFFFFF1A] rounded-[8px] px-[12px] focus:outline-none pl-4"
                type="text"
                name="currentPrice"
                id="currentPrice"
                onChange={handlePriceChange}
                readOnly={coinType === "ETH" ? true : false}
              />
              <p className="absolute right-3 bottom-2">
                {coinType === "ETH" ? coinType : "erc20"}
              </p>
              <p className="absolute left-1 bottom-[9px]">
                {coinType === "ETH" ? '' : "$"}
              </p>
            </div>
            {/* $ Available */}
            <div className="flex flex-col gap-[12px]">
              <div className="flex justify-between align-middle ">
                <label htmlFor="available">Available</label>
                <div className="text-black flex justify-center align-middle px-[8px] pt-[6px] font-bold text-[8px] bg-white rounded-[15px]">
                  <BsArrowUpRight className="text-[#25c78e] h-[15px] w-[15px] p-[4px] rounded-full bg-[#25c78e35] mr-5px mt-[-2px] ml-[-3px]" />
                  <span className="ml-1">Next Floor + $25</span>
                </div>
              </div>
              <input
                value={available}
                className="w-[180px] h-[40px] bg-[#FFFFFF1A] rounded-[8px] px-[12px] focus:outline-none"
                type="text"
                name="available"
                id="available"
                readOnly
              />
            </div>
            {/* $ Official ENS Address */}
            <div className="flex flex-col gap-[12px]">
              <label htmlFor="officialEnsAddrss">Official ENS Address</label>
              <input
                value={"partisians.eth"}
                className="w-[180px] h-[40px] bg-[#FFFFFF1A] rounded-[8px] px-[12px] focus:outline-none"
                type="text"
                name="officialEnsAddrss"
                id="officialEnsAddrss"
                readOnly
              />
            </div>
            {/* $ DROP DOWN */}
            <div className="flex flex-col gap-[12px]">
              <label className="mt-[.71rem]" htmlFor="officialEnsAddrss">
                Quantity
              </label>
              <FormControl className="w-[100%] h-[50px]" size="small">
                <Select
                  value={nftValue}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={FaAngleDown}
                  className="bg-white w-[180px]"
                >
                  {nftValues?.map((el) => (
                    <MenuItem key={nftValues?.indexOf(el)} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {/* $$$ BOTTOM PART */}
        <div>
          {/* $$ TOP PART */}
          <div className="text-white mt-[32px]">
            <h6 className="text-[20px]">What’s Included?</h6>
            <ul className="flex flex-col gap-[12px] list-disc ml-[1rem] mt-[12px]">
              <li>Partisians Hall of Fame Pass and HOF Group Access and mint 🏆</li>
              <li>Early access to ALL Parti.com launchpad offers </li>              
              <li>Auto invite to The Partisians Epic Community Ball (No need for Party Traits) </li>   
              <li>All other Partisians NFT benefits and utilities </li>
              <li>TBD exclusive benefits </li>
              <li>
                Members who purchase 10 or more will receive a free Partisians
                Collectible when they launch!
              </li>
            </ul>
          </div>
          {/* $ BOTTOM IMAGE */}
          <div className="mt-[26px]">
            <iframe
              className="w-[600px] h-[350px] object-contain"
              src="https://www.youtube.com/embed/pWtS-t-Z1i0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
            ></iframe>
          </div>
        </div>
      </div>

      {/* $$$$$$ FOR SMALL DEVICE */}
      <div className="block lg:hidden">
        <Accordion className="bg-tranparent" sx={{ background: "transparent" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h1 className="text-[24px] text-white">
              Partisians Hall of Fame Pass 🏆
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            {/* $$$ TOP PART */}
            <div>
              {/* $$ INPUT FIELDS */}
              <div className="flex flex-wrap items-center justify-between gap-[12px] text-white mt-[26px] tracking-wider whitespace-nowrap">
                {/* $ Current Price */}
                <div className="w-[47%] flex flex-col gap-[12px] relative">
                  <label htmlFor="currentPrice">Current Price</label>
                  <input
                     value={`${coinType === "ETH" ? price.toFixed(4) : price}`}
                    className="w-[100%] h-[40px] bg-[#FFFFFF1A] rounded-[8px] px-[14px] focus:outline-none"
                    type="text"
                    name="currentPrice"
                    id="currentPrice"
                    onChange={handlePriceChange}
                    readOnly={coinType === "ETH" ? true : false}
                  />
                  <p className="absolute right-3 bottom-2">
                {coinType === "ETH" ? coinType : "erc20"}
              </p>
              <p className="absolute left-1 bottom-[8px]">
                {coinType === "ETH" ? '' : "$"}
              </p>
                </div>
                {/* $ Available */}
                <div className="w-[47%] flex flex-col gap-[12px]">
                  <label htmlFor="available">Available</label>
                  <input
                    value={available}
                    className="w-[100%] h-[40px] bg-[#FFFFFF1A] rounded-[8px] px-[12px] focus:outline-none"
                    type="text"
                    name="available"
                    id="available"
                  />
                </div>
                {/* $ Official ENS Address */}
                <div className="w-[47%] flex flex-col gap-[12px]">
                  <label htmlFor="officialEnsAddrss">
                    Official ENS Address
                  </label>
                  <input
                    value={"partisians.eth"}
                    className="w-[100%] h-[40px] bg-[#FFFFFF1A] rounded-[8px] px-[12px] focus:outline-none"
                    type="text"
                    name="officialEnsAddrss"
                    id="officialEnsAddrss"
                    readOnly
                  />
                </div>
                {/* $ DROP DOWN */}
                <div className="w-[47%] flex flex-col gap-[12px]">
                  <label className="mt-[.71rem]" htmlFor="officialEnsAddrss">
                    Quantity
                  </label>
                  <FormControl className="w-[100%] h-[50px]" size="small">
                    <Select
                      value={nftValue}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      IconComponent={FaAngleDown}
                      className="bg-white w-[100%]"
                    >
                      {nftValues?.map((el) => (
                        <MenuItem key={nftValues?.indexOf(el)} value={el}>
                          <p>{el}</p>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            {/* $$$ BOTTOM PART */}
            <div>
              {/* $$ TOP PART */}
              <div className="text-white mt-[32px]">
                <h6 className="text-[20px]">What’s Included?</h6>
                <ul className="flex flex-col gap-[12px] list-disc ml-[1rem] mt-[12px]">
                <li>Partisians Hall of Fame Pass and HOF Group Access and mint 🏆</li>
              <li>Early access to ALL Parti.com launchpad offers </li>              
              <li>Auto invite to The Partisians Epic Community Ball (No need for Party Traits) </li>   
              <li>All other Partisians NFT benefits and utilities </li>
              <li>TBD exclusive benefits </li>
              <li>
                Members who purchase 10 or more will receive a free Partisians
                Collectible when they launch!
              </li>
                </ul>
              </div>
              {/* $ BOTTOM IMAGE */}
              <div className="mt-[26px]">
                <iframe
                  className="w-[100%] object-contain"
                  src="https://www.youtube.com/embed/pWtS-t-Z1i0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default LeftSide;
