import { ethers } from "ethers";
import React, { useState } from "react";
import { collection, addDoc, updateDoc, query, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
signInWithEmailAndPassword(auth, process.env.REACT_APP_EMAIL as string, process.env.REACT_APP_PASSWORD as string)

declare global {
    interface Window {
        ethereum: any;
    }
}

export interface TransactionResponsePayment extends ethers.providers.TransactionResponse {
    network?: ethers.providers.Network,
}

const DEFAULT_ADDRESS = "0x3C661deC5B35068D1462030AbD87F2292682AE56";

const usdc = {
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    abi: [
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function gimmeSome() external",
        "function balanceOf(address _owner) public view returns (uint256 balance)",
        "function transfer(address _to, uint256 _value) public returns (bool success)",
    ],
};
interface Props{
    nftValue: number;
    email: string;
    discord: string;
    coinType:string;
    setShowModal: any;
    errorMessage: string;
    setErrorMessage: any;
    setTotal: any;
    available: number;
    setAvailable: any;
}

const USDC_Payment_Form: React.FC<Props> = ({nftValue, email, discord, coinType, setShowModal, errorMessage, setErrorMessage, setTotal,  setAvailable, available}) =>{
    const navigate = useNavigate();

    const [destinationAddress, setDestinationAddress] = useState(DEFAULT_ADDRESS);

    const [transaction, setTransaction] = useState<TransactionResponsePayment | null>(null);
    let transactionURL = "";
    if (transaction?.hash) {
        transactionURL = `https://${transaction.network?.name === "homestead" ? "" : transaction.network?.name + "."}etherscan.io/tx/${transaction.hash}`
    }

    const startPayment = async (event: any) => { // new line
        setErrorMessage("");
        setTransaction(null);

        event.preventDefault();

        try {
            if (!window.ethereum) {
                throw new Error("No crypto wallet found. Please install it.");
            }

            await window.ethereum.request({
                method: "eth_requestAccounts",
            });

            const provider = new ethers.providers.Web3Provider(window.ethereum);

            const network = await provider.getNetwork();
            if (network.name === "homestead") {
            const signer = provider.getSigner();

            ethers.utils.getAddress(destinationAddress);

            const usdcContract = new ethers.Contract(usdc.address, usdc.abi, signer);

            const transactionResponse = await usdcContract.transfer(destinationAddress, ethers.utils.parseUnits(setTotal.toString(), 6), { gasPrice: 20e9 }) as TransactionResponsePayment;;

            transactionResponse.network = network
            const receipt = await transactionResponse.wait()
            setTransaction(transactionResponse);
            const data = {
                Network: transactionResponse?.network?.name,
                Quantity: nftValue,
                Payment: coinType,
                Email: email,
                Discord: discord,
                Hash: transactionResponse?.hash
            }
            auth.onAuthStateChanged(async function(user) {
                if (user) {
                  //console.log("signed in successfully")
                  const addRef = collection(db, "transaction");
                  addDoc(addRef, data).then((res) =>
                    navigate(`/success/${transactionResponse?.hash}`)
                  );
                  //counter
                  var ref = query(collection(db, "counter"));
                  const querySnapshot = await getDocs(ref);
                  querySnapshot.forEach((doc) => {
                    const currentfigure = doc.data().total
                    const alteredfigure = currentfigure + nftValue
                    updateDoc(doc.ref, { total: alteredfigure })
                    return
                  });
                } else {
                  //console.log("No user is signed in.")
                }
              });
        } else {
            throw new Error("Test Network detected. Please switch to Ethereum Mainnet and refresh to proceed.")
          }
        } catch (error: any) {

            setShowModal(true)
            setErrorMessage(error.message.split("(")[0]);
        }

    }

    return (<div>
        <button
            onClick={(startPayment)} className="h-[50px] w-[100%] bg-black rounded-[10px] tracking-wider">
            Complete Purchase
        </button>
        {transaction &&
            <div className="alert alert-success mt-3 my-3" role="alert">
                Payment Complete:  <a href={transactionURL} target="_blank" rel="noopener noreferrer">
                    View Transaction
                </a>
            </div>
        }
    </div>
    );
}

export default USDC_Payment_Form