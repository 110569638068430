import { useState, useEffect, useRef } from "react";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
// import { FaDiscord, FaTwitter, FaMedium, FaYoutube } from "react-icons/fa";
import Dropdown from "react-dropdown";
import * as htmlToImage from "html-to-image";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TfiReload } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";

import "react-dropdown/style.css";

import { auth, storage } from "../firebase";
import partisansDark from "../Assets/partisiansDark.png";

import bg1 from "../Assets/bg1.jpg";
import bg2 from "../Assets/bg2.jpg";
import bg3 from "../Assets/bg3.jpg";
import bg4 from "../Assets/bg4.jpg";
import bg5 from "../Assets/bg5.jpg";
import bg6 from "../Assets/bg6.jpg";
import bg7 from "../Assets/bg7.jpg";
import { signInWithEmailAndPassword } from "firebase/auth";

signInWithEmailAndPassword(auth, process.env.REACT_APP_EMAIL as string, process.env.REACT_APP_PASSWORD as string)
const bgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7];
const numberOfBgs = 7;

const numberOfPfps = 1000;
const options = Array.from(Array(numberOfPfps).keys()).map((x) => x.toString());
const bgOptions = ["None"].concat(
  Array.from(Array(numberOfBgs).keys()).map((x) => x.toString())
);

const bgValues = [1, 2, 3, 4, 5, 6];

const App = () => {
  const [id, setId] = useState<string>(
    Math.floor(Math.random() * numberOfPfps).toString()
  );
  const [bg, setBg] = useState<number | "None" | null>("None");
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const bgRef = useRef();
  const pfpRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        (async () => {
              if (id) {
                const srcRef = storageRef(storage, `${id}.png`);
                const src = await getDownloadURL(srcRef);
                setImgSrc(src);
              }
            })();
      } else {
        //console.log("No user is signed in.")
      }
    });
    
  }, [id]);

  const takeScreenShot = async (node: HTMLElement) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  const download = (image: string) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = `Partisian #${id}`;
    a.click();
  };

  const downloadScreenshot = () => {
    const ref = bg === "None" ? pfpRef : bgRef;
    // @ts-ignore
    takeScreenShot(ref.current).then(download);
  };

  return (
    <div className="w-full flex flex-col items-center bg-red text-white">
      <div className="w-full max-w-screen-md flex flex-col items-center px-4 py-[60px] sm:px-10 mt-10">
        <div
          className={"w-full flex flex-col items-center"}
          /* @ts-ignore*/
          ref={bgRef}
        >
          {bg !== null && bg !== "None" ? (
            <div className="w-full relative">
              <img src={bgs[bg]} alt="" className="w-full" />
              {imgSrc && (
                <div className="absolute h-full top-0 w-full flex flex-col items-center">
                  <img src={imgSrc} className="h-full" alt="pfp" />
                </div>
              )}
            </div>
          ) : (
            <div
              className="w-full max-w-[300px] relative aspect-square"
              style={{
                backgroundColor: "#0EFDF1",
              }}
              // @ts-ignore
              ref={pfpRef}
            >
              {imgSrc ? (
                <img
                  src={imgSrc}
                  className="absolute top-0 bottom-0 left-0 right-0"
                  alt="pfp"
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <div className="font-bold">Loading...</div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* $$$$$$ DROPDOWN SECTION */}
        <div className="flex gap-[12px] mt-[1rem]">
          {/* $$$ BACKGROUND */}
          <div className="flex flex-col gap-[12px]">
            <p>Background #</p>
            <Dropdown
            options={bgOptions}
            onChange={({ value }) => {
              if (value !== "None") {
                setBg(Number(value));
              } else {
                setBg(value);
              }
            }}
            value={bg?.toString() || "Choose"}
          />
          </div>
          <div className="flex flex-col gap-[12px]">
            <span>Partisian #</span>
            <Dropdown
            options={options}
            onChange={({ value }) => {
              setId(value);
            }}
            value={id || "ID"}
          />
          </div>
        </div>
        {/* $$$$$ BOTTOM BUTTONS */}
        <div className="flex flex-col items-center gap-[34px] mt-[16px]">
          <div className="flex gap-[12px]">
            <button
              onClick={() => {
                if (imgSrc && id !== null) {
                  downloadScreenshot();
                }
              }}
              className="w-[228px] h-[40px] text-white bg-black rounded-[6px] font-bold"
            >
              Download
            </button>
            <button        onClick={() => {
            const random = Math.floor(Math.random() * numberOfPfps);
            setId(random.toString());
          }} className="w-[40px] h-[40px] bg-[#00000033] flex justify-center items-center rounded-[6px]">
              <TfiReload />

            </button>
          </div>
          <p className="text-center px-[24px] py-[12px] rounded-[6px] font-[500] bg-[#FFFFFF2B]">
            Reserve Your Partisians Hall of Fame 🏆 Pass and NFT for .2 ETH
          </p>
          <div>
            <button
              onClick={() => {
                const random = Math.floor(Math.random() * numberOfPfps);
                setId(random.toString());
                navigate('/order')
              }}
              className="w-[228px] h-[40px] text-white bg-black rounded-[6px] font-bold"
            >
              Reserve Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
