import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from "recharts";
import bayc from "../Assets/bayc.png";
import azuki from "../Assets/azuki.png";
import doodles from "../Assets/doodles.png";
import degods from "../Assets/degods.png";
import partisians from "../Assets/partisians.png";
import potatoz from "../Assets/potatoz.png";
import moonbirds from "../Assets/moonbirds.png";
import pudgyPenguins from "../Assets/pudgy.png";
import coolCats from "../Assets/cool-cats.png";
import theRealMVP from "../Assets/the-real.png";
import { fakeData } from "./data";
import axios from "axios";
const CustomXAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    {payload.value === "bored-ape-yacht-club" && (
      <image
        href={bayc}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "azuki" && (
      <image
        href={azuki}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "doodles-official" && (
      <image
        href={doodles}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "degods" && (
      <image
        href={degods}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "partisians" && (
      <image
        href={partisians}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "the-potatoz" && (
      <image
        href={potatoz}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "moonbirds" && (
      <image
        href={moonbirds}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "pudgy-penguins" && (
      <image
        href={pudgyPenguins}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "cool-cats" && (
      <image
        href={coolCats}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value === "you-the-real-mvp" && (
      <image
        href={theRealMVP}
        x="-30"
        y="0"
        height="50"
        width="50"
        style={{ zIndex: "1000" }}
      />
    )}
    {payload.value.split("-").length === 1 && (
      <text
        x={20}
        y={50}
        dy={16}
        textAnchor="end"
        fontWeight="bold"
        fill="#ffffff"
      >
        {payload.value.slice(0, 1).toUpperCase()}
        {payload.value.slice(1, payload.value.length)}
      </text>
    )}
    {payload.value.split("-").length === 2 && (
      <text
        x={35}
        y={50}
        dy={16}
        textAnchor="end"
        fontWeight="bold"
        fill="#ffffff"
      >
        {payload.value.split("-")[0].slice(0, 1).toUpperCase()}
        {payload.value.split("-")[0].slice(1, payload.value.length)}{" "}
        {payload.value.split("-")[1].slice(0, 1).toUpperCase()}
        {payload.value.split("-")[1].slice(1, payload.value.length)}
      </text>
    )}
    {payload.value.split("-").length === 3 && (
      <>
        <text
          x={25}
          y={50}
          dy={16}
          textAnchor="end"
          fontWeight="bold"
          fill="#ffffff"
        >
          {payload.value.split("-")[0].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[0].slice(1, payload.value.length)}{" "}
          {payload.value.split("-")[1].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[1].slice(1, payload.value.length)}
        </text>
        <text
          x={10}
          y={70}
          dy={16}
          textAnchor="end"
          fontWeight="bold"
          fill="#ffffff"
        >
          {" "}
          {payload.value.split("-")[2].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[2].slice(1, payload.value.length)}
        </text>
      </>
    )}
    {payload.value.split("-").length === 4 && (
      <>
        <text
          x={35}
          y={50}
          dy={16}
          textAnchor="end"
          fontWeight="bold"
          fill="#ffffff"
        >
          {payload.value.split("-")[0].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[0].slice(1, payload.value.length)}{" "}
          {payload.value.split("-")[1].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[1].slice(1, payload.value.length)}
        </text>
        <text
          x={35}
          y={70}
          dy={16}
          textAnchor="end"
          fontWeight="bold"
          fill="#ffffff"
        >
          {" "}
          {payload.value.split("-")[2].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[2].slice(1, payload.value.length)}{" "}
          {payload.value.split("-")[3].slice(0, 1).toUpperCase()}
          {payload.value.split("-")[3].slice(1, payload.value.length)}
        </text>
      </>
    )}
  </g>
);

{
  /* <div className="custom-tooltip">
<p>{payload[0].value}</p>
</div> */
}

const CustomTooltip = ({ active, payload, data }) => {
  if (payload.value === "partisians"){
    return (
      <div
        style={{ transform: "none", transition: "none" }}
        className="base:overflow-scroll w-[502px] mx-auto flex gap-[1rem] bg-[whitesmoke] p-[14px] rounded-[8px] outline outline-[#31FFB3] z-50"
      >
        {/* $$$ LEFT SIDE */}
        <div>
          <div>
            <img
              className="min-w-[82px] h-[82px] object-contain outline"
              src={partisians}
              alt="image"
            />
          </div>
          <div className="flex flex-col items-center gap-[.8rem] mt-[1rem] text-black"></div>
        </div>

        {/* $$$$ RIGHT SIDE */}
        <div>
          <div className="text-[#0D0A37]">
            <h1 className="text-[1.2rem] font-bold">{"Partisians"}</h1>
            <div className="flex gap-[1rem] font-bold text-sm">
              <span>FP: 0.2 ETH</span>
              <span>COLLECTION: 10k</span>
              <span>MC: 10k ETH</span>
            </div>
            <p className="text-[14px] mt-[20px] capitalize font-semibold">
              "Partisians NFTs are the Masterpass to Parti.com with more utilities below and to be shared in our upcoming whitepaper release. You can buy Partisians HOF Passes with Partisians NFTs in advance with ETH using Metamask by clicking on Buy Partisians Hall of Fame 🏆 Pass above."
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (active && payload && payload.length) {
    const filteredData = data.filter((d) => d.name === payload[0].payload.name);
    // console.log(filteredData);
    return (
      <div
        style={{ transform: "none", transition: "none" }}
        className="base:overflow-scroll w-[502px] mx-auto flex gap-[1rem] bg-[whitesmoke] p-[14px] rounded-[8px] outline outline-[#31FFB3] z-50"
      >
        {/* $$$ LEFT SIDE */}
        <div>
          <div>
            <img
              className="min-w-[82px] h-[82px] object-contain outline"
              src={filteredData[0]?.img}
              alt="image"
            />
          </div>
          <div className="flex flex-col items-center gap-[.8rem] mt-[1rem] text-black"></div>
        </div>

        {/* $$$$ RIGHT SIDE */}
        <div>
          <div className="text-[#0D0A37]">
            <h1 className="text-[1.2rem] font-bold">{filteredData[0]?.name.replace(/-/g, ' ').toUpperCase()}</h1>
            <div className="flex gap-[1rem] font-bold text-sm">
              <span>FP: {filteredData[0]?.currentPrice} ETH</span>
              <span>COLLECTION: 10k</span>
              <span>MC: {filteredData[0]?.marketCap} ETH</span>
            </div>
            <p className="text-[14px] mt-[20px] capitalize font-semibold">
              {filteredData[0]?.description}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const Charts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dataArray = [];
      for (const i of fakeData) {
        if (i?.name === "partisians")  {
          dataArray.push({
            name: "partisians",
            description: "Partisians NFTs are the Masterpass to Parti.com with more utilities below and to be shared in our upcoming whitepaper release. You can buy Partisians HOF Passes with Partisians NFTs in advance with ETH using Metamask by clicking on Buy Partisians Hall of Fame 🏆 Pass above.",
            marketCap: "100",
            currentPrice: "0.2", 
            img: partisians,
            fullName: "partisians"
          })
        }else if (i?.name === "degods"){
          const response = await fetch(
            `https://api.coingecko.com/api/v3/nfts/${i?.name}`
          );
          const newData = await response.json();
          axios
          .get(
            'https://api.coinbase.com/v2/exchange-rates?currency=ETH'
          )
          .then((res) => {
            if (res.status) {
                dataArray.push({
                  name: newData?.id,
                  description: newData?.description,
                  marketCap: newData?.market_cap?.native_currency / res.data.data.rates.SOL,
                  currentPrice: Math.ceil(newData?.floor_price?.native_currency / res.data.data.rates.SOL),
                  img: newData?.image?.small,
                  fullName: newData?.name,
                });
            }
          });
        }else{
        const response = await fetch(
          `https://api.coingecko.com/api/v3/nfts/${i?.name}`
        );
        const newData = await response.json();
          // console.log(newData)
        dataArray.push({
          name: newData?.id,
          description: newData?.description,
          marketCap: newData?.market_cap?.native_currency,
          currentPrice: Math.ceil(newData?.floor_price?.native_currency),
          img: newData?.image?.small,
          fullName: newData?.name,
        });
      }
      }
      setData(dataArray);
    };
    fetchData();

    // Update every minute
    const intervalId = setInterval(() => {
      fetchData();
    }, 600000);

    // Clean up interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  // console.log(data);
  return (
    <div className="flex justify-center bg-red text-white mt-24 overflow-x-scroll lg:overflow-x-hidden">
      <div className="w-[90vw] mx-auto">
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <BarChart
          width={1500}
          height={700}
          data={data}
          margin={{
            top: 20,
            right: 100,
            left: 100,
            bottom: 150,
          }}
        >
          {/* {console.log()} */}
          <CartesianGrid strokeDasharray="2" stroke="#FFFFFF" />
          <XAxis
            dataKey="name"
            stroke="#ffffff"
            strokeWidth={3}
            tick={(props) => <CustomXAxisTick {...props} />}

          />
          {/* <XAxis dataKey="image"   tick={(props) => console.log(props.payload.value)} /> */}
          {/* <XAxis dataKey="image" tick={<CustomXAxisTick />} /> */}
          <YAxis
            label={{
              value: "MARKET CAP (ETH)",
              angle: -90,
              position: "outsideLeft",
              fill: "#ffffff",
              fontWeight: "bolder",
            }}
            yAxisId="left"
            orientation="left"
            stroke="#ffffff"
            strokeWidth={2}
            width={180}  
          />
          <YAxis
          label={{
            value: "CURRENT PRICE (ETH)",
            angle: 90,
            position: "outsideLeft",
            fill: "#ffffff",
            fontWeight: "bolder",
          }}
            yAxisId="right"
            orientation="right"
            stroke="#ffffff"
            strokeWidth={2}
            width={100}  
          />
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip data={data} />} />
          {/* <Legend /> */}
          <Bar
            yAxisId="left"
            dataKey="marketCap"
            fill="#ffffff"
            activeFill="#000000"
          />
          <Bar
            yAxisId="right"
            dataKey="currentPrice"
            fill="#000000"
            activeFill="#000000"
          />
        </BarChart>
        {/* </ResponsiveContainer> */}
      </div>
    </div>
  );
};

export default Charts;
