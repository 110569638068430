
import { useState } from "react";
 const TOS= () => {
    const [showTOS, setTOS] = useState(false);
    const onClick = () => setTOS(!showTOS)
    return (
        <div onClick={onClick} >
           <h1 className="cursor-pointer text-white"> Terms of Service</h1>
         
          { showTOS? <DATA /> : null }
        </div>
      )

    
  
};
export default TOS;

const DATA=()=>{
    return(
    <div>
    <p className="cursor-pointer text-white">
     By purchasing, acquiring, or otherwise receiving an NFT that
      originated from Partisians (“Partisian NFT”), you agree to these NFT
      Terms. These NFT Terms, along with Partisians User Terms of Service,
      incorporated herein by reference, are collectively referred to as the
      “Agreement.” OWNERSHIP OF Partisian NFTS AND ARTWORK. Each Partisian NFT
      is a limited-edition digital asset that embodies or includes certain
      artwork owned or created by Partisians (the “Artwork”). You acknowledge
      and agree that Partisians (“Partisians”) owns all legal right, title and
      interest in and to the Artwork, and all intellectual property rights
      therein. Partisian NFTs may also incorporate one or more elements of
      Partisians’s trademarks and branding (the “Brand”). Each Partisian NFT
      is a non-fungible token hosted on a third-party blockchain, ownership of
      which can be transferred through gift, purchase, or sale. The Artwork is
      neither stored nor embedded in Partisians NFT, but is accessible through
      Partisians NFT. ALTHOUGH Partisians NFT ITSELF IS OWNED BY ITS
      THEN-CURRENT OWNER, THE ARTWORK EMBODIED THEREBY AND ANY BRAND
      INCORPORATED THEREIN OR USED THEREWITH IS LICENSED, PURSUANT TO THESE
      NFT TERMS, AND NOT TRANSFERRED OR SOLD, TO SUCH OWNER. “OWN” MEANS, WITH
      RESPECT TO A Partisian NFT, A Partisian NFT THAT YOU HAVE RIGHTFULLY AND
      LAWFULLY PURCHASED OR ACQUIRED FROM A LEGITIMATE SOURCE, WHERE PROOF OF
      SUCH PURCHASE OR ACQUISITION IS RECORDED ON THE RELEVANT BLOCKCHAIN. The
      rights that you have in and to the Artwork are limited to those
      expressly stated in the paragraph immediately below. Partisians and its
      licensors reserve all rights in and to the Artwork and Brand not
      expressly granted to you herein. LICENSES. Subject to your lawful
      purchase or acquisition of a Partisian NFT and your continued compliance
      with this Agreement, Partisians grants you a worldwide, non-exclusive,
      revocable, royalty-free license, with no right to sub-license, (a) to
      display the Artwork and any Brand embodied by your Partisian NFTs,
      solely for the following purposes: (i) for your own personal,
      non-commercial use (e.g., display in a virtual gallery, on a mobile
      device, as an avatar, or in your home); (ii) through or as a part of a
      marketplace that permits the purchase and sale of your Partisian NFTs in
      connection with such potential purchase or sale, provided that the
      marketplace cryptographically verifies rights to ensure that only the
      actual owner can offer a Partisian NFT for sale; or (iii) as part of a
      third party website or application that permits the inclusion,
      involvement, or participation of your Partisian NFTs, provided that the
      website/application cryptographically verifies rights to ensure that
      only the actual owner can display Partisians NFT; and (b) to access
      certain limited features or exercise certain privileges on or through
      Partisians website and/or mobile application(s) (collectively, the
      “Partisian Digital Properties”), in each case in accordance with the
      functionalities of such Partisians Digital Properties and as may be
      modified by Partisians from time to time in Partisians’s sole
      discretion. Partisians provides Partisians NFT, licenses the Artwork and
      Brand to the owner of Partisians NFT, and provides Partisians Digital
      Properties on an “AS IS” basis, and expressly disclaims any warranties
      or conditions of any kind, either express or implied, including, without
      limitation, any warranties or conditions of title, non-infringement,
      merchantability, or fitness for a particular purpose. The license to
      display the Artwork and any Brand embodied by Partisians NFT is
      automatically and always transferred with the NFT as provided below.
      Otherwise the license to display the Artwork and any Brand embodied by
      Partisians NFT is non-transferable. You acknowledge that the license
      granted to you in this Agreement applies only to the extent that you
      lawfully purchased or acquired Partisians NFT and, with respect to a
      Partisian NFT that you purchase, that you continue to own such Partisian
      NFT. If at any time you sell, trade, donate, give away, transfer, or
      otherwise dispose of your Partisian NFT for any reason, the license
      granted in this Agreement to you will immediately expire with respect to
      such Partisian NFT without the requirement of notice, and you will have
      no further rights in or to the Artwork or Brand embodied by such
      Partisian NFT. The license granted in this Agreement will also
      automatically terminate, with all rights returning to Partisians, if:
      (x) you breach this Agreement, which shall include, transferring or
      acquiring Partisians NFT in a way not specifically permitted hereunder
      or (y) you engage in any unlawful business practice related to
      Partisians NFT. In the event that you did not lawfully purchase or
      acquire Partisians NFT or upon any termination of the license granted in
      this Agreement, Partisians may disable your access to the Artwork and/or
      Brand and/or deny you access to any further benefits, services or goods
      associated with Partisians NFT. RESTRICTIONS. You agree that you will
      not, nor will you permit any third party to, do or attempt to do any of
      the following without Partisians’s express prior written consent in each
      case: (i) modify, distort or perform any other change to the Artwork or
      Brand embodied by your Partisian NFT in any way, including, without
      limitation, the shapes, designs, drawings, attributes, or color schemes;
      (ii) use the Artwork or Brand embodied by your Partisian NFTs as a brand
      or trademark or to advertise, market, or sell any product or service
      (other than an authorized offering of Partisians NFT itself); (iii) use
      the Artwork or Brand embodied by your Partisian NFTs in connection with
      images, videos, or other forms of media that depict hatred, intolerance,
      violence, cruelty, or anything else that could reasonably be found to
      constitute hate speech or otherwise infringe upon the rights of others
      or promote illegal activities; (iv) use the Artwork or Brand embodied by
      your Partisian NFTs in movies, videos, or any other forms of media,
      except solely for your own personal, non-commercial use; (v) sell,
      distribute for commercial gain (including, without limitation, giving
      away in the hopes of eventual commercial gain), or otherwise
      commercialize merchandise that includes, contains, or consists of the
      Artwork or Brand embodied by your Partisian NFTs; (vi) attempt to
      trademark, copyright, or otherwise acquire additional intellectual
      property rights in or to the Artwork or Brand embodied by your Partisian
      NFTs; (vii) use the Artwork or Brand embodied by your Partisian NFTs in
      connection with defamatory or dishonest statements about Partisians
      and/or its affiliated companies or which otherwise damage the goodwill,
      value or reputation of Partisians or represent or imply that your
      exercise of the licenses granted hereunder is endorsed by Partisians
      and/or its affiliated companies; or (viii) otherwise utilize the Artwork
      Brand embodied by your Partisian NFTs for your or any third party’s
      commercial benefit (other than an authorized offering of Partisians NFT
      itself). These restrictions will survive the expiration or termination
      of licenses granted hereunder. Without limiting the foregoing, the
      license granted by this Agreement does not include: (i) the right to use
      the Artwork or Brand in connection with additional NFTs; or (ii) the
      right to create derivative works of the Artwork. You may not use or
      attempt to register any asset, including any domain names, social media
      accounts or related addresses, that contains or incorporates any
      Artwork, Brand, or any other representation, name or mark that may be
      confusingly similar to any of Partisians’s intellectual property.
      TRANSFERRING YOUR Partisian NFT. You may transfer any Partisian NFT that
      you lawfully own to a third party, subject to this Agreement. After any
      such transfer, your licenses to the Artwork and Brand will immediately
      terminate. Your licenses will also immediately terminate if you breach
      this Agreement. If you transfer any Partisian NFT, you must make the
      transferee aware of, and you must cause any such transferee to agree in
      writing to comply with and be bound by, these Terms. You acknowledge and
      agree that any transfer of this Partisian NFT, by you or by the
      then-current owner, is subject to a fee payable to Partisians equal to
      5% of the total consideration paid for Partisians NFT in connection with
      any such transfer. Partisians HOF The Partisians HOF is a pre-mint NFT.
      1 HOF = 1 Partisians NFT at the
    </p>
  </div>)
}