import { ethers } from "ethers";
import React, { useState } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  query,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
signInWithEmailAndPassword(auth, process.env.REACT_APP_EMAIL as string, process.env.REACT_APP_PASSWORD as string)
declare global {
  interface Window {
    ethereum: any;
  }
}

export interface TransactionResponsePayment
  extends ethers.providers.TransactionResponse {
  network?: ethers.providers.Network;
}



const alpha_pass = {
  address: "0xd14b041e5576B7B47066AD6b643906860BE30Ce8",
  abi: [
    "function mint(uint256 amount) external payable",
    "function currentPrice() public view returns(uint256)",
  ],
};
interface Props {
  nftValue: number;
  email: string;
  discord: string;
  coinType: string;
  setShowModal: any;
  errorMessage: string;
  setErrorMessage: any;
  setTotal: any;
  available: number;
  setAvailable: any;
}

const NFT_MINT_FORM: React.FC<Props> = ({
  nftValue,
  email,
  discord,
  coinType,
  setShowModal,
  errorMessage,
  setErrorMessage,
  setTotal,
  setAvailable,
  available,
}) => {
  const navigate = useNavigate();


  const [transaction, setTransaction] =
    useState<TransactionResponsePayment | null>(null);
  let transactionURL = "";
  if (transaction?.hash) {
    transactionURL = `https://${
      transaction.network?.name === "homestead"
        ? ""
        : transaction.network?.name + "."
    }etherscan.io/tx/${transaction.hash}`;
  }

  const startPayment = async (event: any) => {
    // new line
    setErrorMessage("");
    setTransaction(null);

    event.preventDefault();

    try {
      if (!window.ethereum) {
        throw new Error("No crypto wallet found. Please install it.");
      }

      await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const network = await provider.getNetwork();

      const signer = provider.getSigner();
      if (network.name === "homestead") {
        const alpha_passContract = new ethers.Contract(
          alpha_pass.address,
          alpha_pass.abi,
          signer
        );
        const price = await alpha_passContract.currentPrice();

        const eth_value = price.mul(nftValue);
       
        const transactionResponse = (await alpha_passContract.mint(nftValue, {        
          value: eth_value,
        })) as TransactionResponsePayment;

        transactionResponse.network = network;
        //const receipt = await transactionResponse.wait();
        setTransaction(transactionResponse);
        const data = {
          Network: transactionResponse?.network?.name,
          Quantity: nftValue,
          Payment: coinType,
          Email: email,
          Discord: discord,
          Hash: transactionResponse?.hash,
        };
        auth.onAuthStateChanged(async function(user) {
          if (user) {
            //console.log("signed in successfully")
            const addRef = collection(db, "transaction");
            addDoc(addRef, data).then((res) =>
              navigate(`/success/${transactionResponse?.hash}`)
            );
            //counter
            var ref = query(collection(db, "counter"));
            const querySnapshot = await getDocs(ref);
            querySnapshot.forEach((doc) => {
              const currentfigure = doc.data().total
              const alteredfigure = currentfigure + nftValue
              updateDoc(doc.ref, { total: alteredfigure })
              return
            });
          } else {
            //console.log("No user is signed in.")
          }
        });
      } else {
        throw new Error(
          "Test Network detected. Please switch to Ethereum Mainnet and refresh to proceed."
        );
      }
    } catch (error: any) {
      setShowModal(true);
      setErrorMessage(error.message.split("(")[0]);
    }
  };

  return (
    <div>
      <button
        onClick={startPayment}
        className="h-[50px] w-[100%] bg-black rounded-[10px] tracking-wider"
      >
        Complete Purchase
      </button>
      {transaction && (
        <div className="alert alert-success mt-3 my-3" role="alert">
          Payment Complete:{" "}
          <a href={transactionURL} target="_blank" rel="noopener noreferrer">
            View Transaction
          </a>
        </div>
      )}
    </div>
  );
};

export default NFT_MINT_FORM;
