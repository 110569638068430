import { ethers } from "ethers";
import React, { useState } from "react";

async function getAccount() {
    const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
    });
    const account = accounts[0];
    return account;
}


export default function ConnectButton() {
    const [accountAddress, setAccountAddress] = useState("");
    const [accountBalance, setAccountBalance] = useState("");
    const [mainnetwork, setMainNetwork] = React.useState<boolean>(false);

    const provider = ((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum) : ethers.providers.getDefaultProvider());
    const connectButtonOnClick = () => {
        if (typeof window !== "undefined" && window.ethereum != null) {
            getAccount().then((account_response) => {
                provider.getNetwork().then((response) =>{
                    // if (response.name === "homestead"){
                        provider.getBalance(account_response).then((balance) => {
                            const balanceInEth = ethers.utils.formatEther(balance)
                            const rounded = Math.round(Number(balanceInEth) * 100000) / 100000;
                            setAccountBalance(rounded + ` ETH`)
                           })
                        setAccountAddress(account_response);
                    // }
                    // else{
                    //     return alert('Test network detected. Please change to Ethereum Mainnet and refresh to proceed.')
                    // }
                    return response.name
                })
               
            });
        } else {
            return alert('Please install metamask wallet')
          }
    }
    return (
        <button onClick={connectButtonOnClick} className="w-[178px] h-[46px] rounded-[8px] tracking-wider bg-[#FFFFFF54]">
            {!!accountBalance ? accountBalance : "Connect Wallet"}
        </button>
    )
}


// export default Login

