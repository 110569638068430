import { Drawer } from "@mui/material";
import { useState } from "react";
import { BiMenu } from "react-icons/bi";
import { BsExclamationCircleFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import ModalComp from "./ModalComp";

const NavBar = () => {
  // DRAWER STATE
  const [openDrawer, setDrawer] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      {/* $$$$$$ NAVBAR FOR LARGE DEVICE */}
      <div className="hidden text-white py-[27px] lg:flex items-center justify-between whitespace-nowrap">
        {/* $$$ LEFT SIDE */}
        <div className="flex items-center gap-[1rem]">
          <h1 onClick={()=> navigate('/')} className="text-[34px] font-edosz cursor-pointer">Partisians.live</h1>
          <i onClick={()=> setShowModal(true)} className="flex items-center gap-[8px] px-[12px] py-[13px] rounded-[8px] bg-[#FFFFFF21] underline cursor-pointer">
            <BsExclamationCircleFill /> Safety Tips For Safe Transaction
          </i>
        </div>
        {/* $$$ RIGTH SIDE */}
        <div className="flex items-center gap-[20px] font-bold">
          <h1 onClick={()=> navigate('/order')} className="cursor-pointer">Buy Partisians Hall of Fame 🏆 Pass</h1>
          <h1 onClick={()=> navigate('/charts')} className="cursor-pointer">Explore NFT Trends 🔥 </h1>
          <Login />
        </div>
      </div>
      {/* $$$$$$ NAVBAR FOR SMALL DEVICE */}
      <div className="flex flex-col text-white p-[20px] lg:hidden justify-between whitespace-nowrap relative">
        {/* $$$ LEFT SIDE */}
        <div className="flex justify-between items-center gap-[1rem]">
          <h1 onClick={()=> navigate('/')} className="text-[20px] font-edosz cursor-pointer">Partisians.live</h1>
          <span
            onClick={() => setDrawer(true)}
            className="text-[1.5rem] cursor-pointer"
          >
            <BiMenu />
          </span>
        </div>
        <Drawer
          anchor={"right"}
          open={openDrawer}
          onClose={() => setDrawer(false)}
        >
          <div className="h-screen bg-[#24C88B] flex flex-col items-center gap-[20px] font-bold py-[36px] px-[20px] relative">
            {/* $$$ CROSS ICON */}
            <span onClick={() => setDrawer(false)} className="absolute left-[12px] top-[8px] text-[1.5rem]">
              <MdClose />
            </span>
            <i onClick={()=> setShowModal(true)} className="flex items-center gap-[8px] px-[12px] py-[13px] rounded-[8px] bg-[#FFFFFF21] underline cursor-pointer">
              <BsExclamationCircleFill /> Safety Tips For Safe Transaction
            </i>
            <h1 onClick={()=> navigate('/order')} className="cursor-pointer">Buy Partisians Hall of Fame 🏆 Pass</h1>
            <h1 onClick={()=> navigate('/charts')} className="cursor-pointer">Explore NFT Trends 🔥 </h1>
            <Login />
          </div>
        </Drawer>
        {/* $$$ RIGTH SIDE */}
        <ModalComp showModal={showModal} setShowModal={setShowModal} />
      </div>
    </>
  );
};

export default NavBar;
