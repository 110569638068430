import logo from "../../Assets/partisian-white.png";

import { FaDiscord, FaTwitter, FaMedium, FaYoutube } from "react-icons/fa";
import TOS from './TOS'
import Priv from "./Privacy";
const openInNewTab = (url: string) => {
  window?.open(url, "_blank")?.focus();
}
const FooterComp = () => {
  return (
    <div className="flex flex-col items-center py-[1.5rem]">
      <img className="w-[67px] h-[38px] object-contain" src={logo} alt="logo" />
      <p className="text-white mt-[8px]">Powered by The Partisians NFT</p>
      <div className="flex gap-[32px] mt-[12px]">
      <FaDiscord
            onClick={() => {
              openInNewTab("http://discord.gg/partisiansnft");
            }}
            className="cursor-pointer text-white"
            size={30}
          />
          <FaTwitter
            onClick={() => {
              openInNewTab("https://twitter.com/PartisiansNFT");
            }}
            className="cursor-pointer text-white"
            size={30}
          />
          <FaMedium
            onClick={() => {
              openInNewTab(
                "https://medium.com/partisiansnft/introducing-the-partisians-the-original-nft-collection-on-partisia-blockchain-be33da253141"
              );
            }}
            className="cursor-pointer text-white"
            size={30}
          />
          <FaYoutube
            onClick={() => {
              openInNewTab("https://www.youtube.com/watch?v=pWtS-t-Z1i0");
            }}
            className="cursor-pointer text-white"
            size={30}
          />
      </div>
      <Priv/>
      <TOS />      
    </div>
  );
};

export default FooterComp;
