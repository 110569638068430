import React from "react";
import NavBar from "./Components/Shared/NavBar";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Order from "./Pages/Order";
import Success from "./Pages/Success";
import FooterComp from "./Components/Shared/FooterComp";
import Charts  from "./Pages/Charts";
const App = () => {
  
  return (
    <div className="max-w-[1324px] mx-auto">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/order" element={<Order />} />
        <Route path="/success/:id" element={<Success />} />
        <Route path="/charts" element={<Charts />} />
      </Routes>
      <FooterComp />
    </div>
  );
};

export default App;
