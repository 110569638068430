import { Dialog, DialogContent } from "@mui/material";
import { IoClose } from "react-icons/io5";

interface Props{
showModal: boolean,
setShowModal: any,
errorMessage: string,
}
const ErrorModal: React.FC<Props> = ({ showModal, setShowModal, errorMessage }) => {
  return (
    <Dialog maxWidth='md' onClose={() => setShowModal(false)} open={showModal}>
      {" "}
      <DialogContent>
        {" "}
        {/* $$$$$$ CROSS BUTTON */}{" "}
        <span
          onClick={() => setShowModal(false)}
          className="right-[7px] top-[7px] text-[1.3rem] text-red-600 absolute"
        >
          {" "}
          <IoClose />{" "}
        </span>{" "}
        <div className="w-[100%] p-3 pb-1">
          {" "}
          <p className="whitespace-wrap">{errorMessage}</p>{" "}
          <div className="flex justify-center">
          <button  onClick={() => setShowModal(false)} className="px-[1rem] py-[.3rem] mt-3 bg-green-400 text-white">Okay</button>
          </div>
        </div>{" "}
      </DialogContent>{" "}
    </Dialog>
  );
};
export default ErrorModal;
