import successIcon from "../Assets/success-icon.png";
import partisiansLogo from "../Assets/PartisiansVideo.mp4";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";


const Success = () => {
  const {id} = useParams();
  
  return (
    <div className="flex flex-col items-center py-[24px] lg:py-[70px] text-white">
      <img src={successIcon} alt="icon" />
      <h2 className="text-[32px] font-bold tracking-wider mt-[32px]">
        Thank You
      </h2>
      <p className="lg:w-[521px] tracking-wider leading-[27px] text-center mt-[24px]">
        Thanks for your early support. You will receive an email confirmation
        with more information and be added to The Partisians Hall of Fame
        Discord Group shortly. Onwards! 🚀
      </p>
      <p className="mt-[32px] text-center">
        Here is your transaction hash:   
        <a href={"https://www.etherscan.io/tx/"+id} target="_blank" rel="noopener noreferrer">{" " + id}</a>
      </p>
      <video
        className="w-[243px] h-[257px] object-contain mt-[24px]"
        src={partisiansLogo} autoPlay loop muted
      />
    </div>
  );
};

export default Success;
